import React from 'react';
import 'animate.css';
import Dead from './images/isecashdead-tile.png';
import Cashening from './images/cashening-tile.png';
import Kill from './images/kill-the-zeros-tile.png';
import Scorecard from './images/scorecard-tile.png';
import Supply from './images/supply-tile.png';
import Avalanche from './images/avalanche-tile.png';
import Holdings from './images/holdings-tile.png';
import Pizza from './images/pizzaday-tile.png';
import Buy from './images/buy-tile.png';
import Land from './images/land-tile.png';
import Telegram from './images/telegram-icon.png';
import Twitter from './images/twitter-icon.png';
import Reddit from './images/reddit-icon.png';
import Discord from './images/discord-icon.png';
import Facebook from './images/facebook-icon.png';
import Instagram from './images/instagram-icon.png';
import './sitelink-styles.css';

const sites = [
  {
    h4: 'Avalanche',
    p: 'The Avalanche protocol on eCash',
    img: Avalanche,
    href: 'https://avalanche.cash',
  },
  {
    h4: 'Buy eCash',
    p: 'Find the best places to buy eCash',
    img: Buy,
    href: 'https://buyecash.com',
  },
  {
    h4: 'Scorecard',
    p: 'Find the best eCash exchange experience',
    img: Scorecard,
    href: 'https://scorecard.cash',
    new: true,
  },
  {
    h4: 'The Cashening',
    p: 'When will XEC flip BCH? Find out here',
    img: Cashening,
    href: 'https://cashening.com',
  },
  {
    h4: 'eCash Supply',
    p: 'Learn more about the total eCash supply',
    img: Supply,
    href: 'https://ecash.supply',
  },
  {
    h4: 'Is eCash Dead?',
    p: 'Find out if eCash is dead or alive',
    img: Dead,
    href: 'https://isecashdead.com',
  },
  {
    h4: 'eCash Pizza Day',
    p: 'Learn about the history of Pizza Day',
    img: Pizza,
    href: 'https://ecash.pizza/',
  },
  {
    h4: 'Kill the Zeros',
    p: 'Destroy the zeros to pump the price',
    img: Kill,
    href: 'https://killthezeros.cash',
  },
  {
    h4: 'eCash Holdings',
    p: 'How much eCash are you holding?',
    img: Holdings,
    href: 'https://ecash.holdings',
  },
  {
    h4: 'eCash Land',
    p: 'Unleash your creativity one pixel at a time',
    img: Land,
    href: 'https://ecash.land',
  },
];

const socials = [
  {
    img: Telegram,
    href: 'https://t.me/ecash',
  },
  {
    img: Discord,
    href: 'https://discord.gg/BxUpjWpjtY',
  },
  {
    img: Reddit,
    href: 'https://www.reddit.com/r/ecash/',
  },
  {
    img: Twitter,
    href: 'https://twitter.com/ecashofficial',
  },
  {
    img: Facebook,
    href: 'https://www.facebook.com/groups/ecashofficial',
  },
  {
    img: Instagram,
    href: 'https://www.instagram.com/ecashnetwork/',
  },
];

const Tile = ({ href, img, alt, h4, p }) => {
  return (
    <a className="sitelink-tile" href={href} target="_blank" rel="noreferrer">
      <img src={img} alt={alt} />
      <div className="tile-text">{p}</div>
    </a>
  );
};

const SocialIcon = ({ href, img }) => {
  return (
    <a className="social-link" href={href} target="_blank" rel="noreferrer">
      <img src={img} alt={'ecash social'} />
    </a>
  );
};

const SiteLinks = () => {
  return (
    <div className="sitelink-ctn" style={{ animationDelay: '1s' }}>
      <h3>Other Cool Sites</h3>
      <div className="sitelink-inner-ctn">
        {sites.map((site) => {
          if (window.location.hostname === site.href.substring(8)) {
            return null;
          } else
            return (
              <Tile
                key={site.href}
                href={site.href}
                img={site.img}
                alt={site.h4}
                h4={site.h4}
                p={site.p}
              />
            );
        })}
      </div>
      <div className="social-link-ctn">
        {socials.map((social) => {
          return (
            <SocialIcon key={social.href} href={social.href} img={social.img} />
          );
        })}
      </div>
    </div>
  );
};

export default SiteLinks;
