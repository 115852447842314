import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Header from './Components/Header';
import ExchangeTile from './Components/ExchangeTile';
import { customStyles } from './Components/SelectStyle';
import SiteLinks from './SiteLinks/SiteLinks';
import { fiatList } from './fiatList.js';

const PairListPage = ({ match, data, loading, tradingPairs }) => {
  const isMounted = useRef(false);
  const isMounted2 = useRef(false);
  const history = useHistory();
  const {
    params: { pair },
  } = match;
  const [selectedOption, setSelectedOption] = useState({
    label: pair,
    image: '/logos/' + pair.toLowerCase() + '.png',
  });

  const [exchangedata, setExchangeData] = useState([
    {
      attributes: {
        name: 'Exchange Name',
        ecash_brand: true,
        decimal_places: 0,
        ecash_deposit_address_format: true,
        url: '/',
        score: 0,
        deposits_working: true,
        withdrawals_working: true,
        withdrawal_fee: 10,
        fiat_deposit_options: ['USD'],
        trading_pairs: ['USDT'],
        logo: {
          data: {
            attributes: { url: '/' },
          },
        },
      },
    },
  ]);

  const GetScores = (exchangeData) => {
    for (let i = 0; i < exchangeData.length; ++i) {
      const element = exchangeData[i].attributes;
      let score = 0;
      const withdrawal = element.withdrawals_working;
      const deposit = element.deposits_working;
      const address = element.ecash_deposit_address_format;
      const depositConfirmations = element.deposit_confirmations;
      const withdrawalFee = element.withdrawal_fee;
      const pairs = element.trading_pairs;
      const decimals = element.decimal_places;

      if (withdrawal) {
        score += 20;
      }
      if (deposit) {
        score += 20;
      }
      if (address && address !== null) {
        score += 20;
      }
      if (depositConfirmations <= 1 && depositConfirmations !== null) {
        score += 15;
      }
      if (withdrawalFee <= 100 && withdrawalFee !== null) {
        score += 10;
      }
      if (
        withdrawalFee <= 1000 &&
        withdrawalFee > 100 &&
        withdrawalFee !== null
      ) {
        score += 5;
      }
      if (pairs.length >= 2) {
        score += 10;
      }
      if (decimals === 2) {
        score += 5;
      }
      element.score = (score / 100) * 100;
    }
    exchangeData.sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name)
    );
    exchangeData.sort(function (a, b) {
      return b.attributes.score - a.attributes.score;
    });
    setExchangeData(exchangeData);
  };

  const routeChange = () => {
    let path = `${selectedOption.value}`;
    history.push(path);
  };

  const noMatch = () => {
    let path = '/exchanges';
    history.push(path);
  };

  useEffect(() => {
    if (isMounted2.current === 2 && exchangedata.length === 0) {
      noMatch();
    } else if (isMounted2.current === 1) {
      isMounted2.current = 2;
    } else {
      isMounted2.current = 1;
    }
  }, [exchangedata]);

  useEffect(() => {
    if (isMounted.current) {
      routeChange();
    } else {
      isMounted.current = true;
    }
  }, [selectedOption]);

  useEffect(() => {
    const Filter = (data) => {
      if (pair === 'exchanges') {
        return data;
      } else {
        let result = data.filter(function (currentElement) {
          return (
            currentElement.attributes.trading_pairs.includes(
              pair.toUpperCase()
            ) ||
            currentElement.attributes.fiat_deposit_options.includes(
              pair.toUpperCase()
            )
          );
        });
        return result;
      }
    };
    GetScores(Filter(data));
  }, [data, pair]);

  return (
    <>
      <Header />
      <div className="pair-page-selector-ctn">
        <h2>
          I want to buy <span>XEC</span> with
        </h2>
        <div className="select-ctn select2-ctn">
          <Select
            defaultValue={pair.toUpperCase()}
            onChange={setSelectedOption}
            options={tradingPairs}
            isSearchable
            styles={customStyles}
            placeholder={
              pair === 'exchanges' ? (
                'Select...'
              ) : (
                <div className="currency-option">
                  {fiatList.some(
                    (fiats) => fiats.currency.code.toLowerCase() === pair
                  ) ? (
                    <img
                      src={
                        'data:image/png;base64,' +
                        fiatList.find(
                          (o) => o.currency.code.toLowerCase() === pair
                        ).flag
                      }
                      alt={selectedOption.label}
                    />
                  ) : (
                    <img
                      src={selectedOption.image}
                      alt={selectedOption.label}
                    />
                  )}
                  <span>{selectedOption.label.toUpperCase()}</span>
                </div>
              )
            }
            classNamePrefix="react-select2"
            formatOptionLabel={(currency) => (
              <div className="currency-option">
                {currency.image.includes('/logos/') ? (
                  <img src={currency.image} alt={currency.label} />
                ) : (
                  <img
                    src={'data:image/png;base64,' + currency.image}
                    alt={currency.label}
                  />
                )}
                <span>{currency.label}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div className="pair-page-wrapper">
        <div className="pair-page-subheadline">
          You can buy <span>eCash (XEC)</span>{' '}
          {pair !== 'exchanges' && (
            <>
              with <span>{pair.toUpperCase()}</span>
            </>
          )}{' '}
          from these exchanges{' '}
        </div>
        {loading && <div>Loading...</div>}
        {!loading && (
          <div className="exchange-tile-ctn">
            {exchangedata.map((item, index) => {
              return (
                <ExchangeTile
                  key={index}
                  logo={item.attributes.logo}
                  name={item.attributes.name}
                  trading_pairs={item.attributes.trading_pairs}
                  url={item.attributes.url}
                  fiat_deposit_options={item.attributes.fiat_deposit_options}
                  score={item.attributes.score.toFixed()}
                />
              );
            })}
            <div className="score-disclaimer">
              *The exchange score is a reflection of the overall eCash
              experience based on a number of factors. For more details visit{' '}
              <a
                href="https://scorecard.cash/"
                target="_blank"
                rel="noreferrer"
              >
                Scorecard.cash
              </a>
              .<br />
              If you find something on an exchange that is not consistent with
              this information, please let us know by opening an issue on the
              public{' '}
              <a
                href="https://github.com/ecashxec/ecash-services/issues"
                target="_blank"
                rel="noreferrer"
              >
                GitHub project
              </a>
              .
            </div>
          </div>
        )}
      </div>
      <SiteLinks />
    </>
  );
};

export default PairListPage;
