import React, { useEffect, useState } from 'react';
import BTC from '../images/btc-icon.png';
import ETH from '../images/eth-icon.png';
import BCH from '../images/bch-icon.png';
import XEC from '../images/xec-icon.png';
import BNB from '../images/bnb-icon.png';
// import LUNA from './images/luna-icon.png';
// import XRP from './images/xrp-icon.png';
// import SOL from './images/sol-icon.png';

const GetIcon = (ticker) => {
  switch (ticker) {
    case 'BTC':
      return BTC;
    case 'ETH':
      return ETH;
    case 'BCH':
      return BCH;
    case 'XEC':
      return XEC;
    case 'BNB':
      return BNB;
    default:
      return BTC;
  }
};

const PriceGroup = ({ ticker, price, change }) => {
  return (
    <div className="ticker-group-ctn">
      <img src={GetIcon(ticker)} alt={ticker} />
      <div style={{ fontWeight: '700' }}>{ticker}</div>
      <div className="ticker-price">
        {ticker === 'XEC'
          ? '$' + price.toFixed(8)
          : '$' + price.toLocaleString()}
      </div>
      <div style={change > 0 ? { color: 'green' } : { color: 'red' }}>
        {change}%
      </div>
    </div>
  );
};

const TickerScroll = () => {
  const [prices, setPrices] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2C%20ethereum%2C%20ecash&order=market_cap_asc&per_page=100&page=1&sparkline=false',
      {}
    )
      .then((res) => res.json())
      .then((response) => {
        const priceData = response;
        setPrices(priceData);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div
      className="ticker-ctn-main animate__animated animate__fadeInUp"
      style={{ animationDelay: '.3s' }}
    >
      <div className="ticker-ctn">
        {loading && <div>Loading...</div>}
        {!loading && (
          <>
            {prices.map((item) => {
              return (
                <PriceGroup
                  key={item.symbol}
                  ticker={item.symbol.toUpperCase()}
                  price={item.current_price}
                  change={item.price_change_percentage_24h.toFixed(2)}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default TickerScroll;
