import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './LandingPage';
import PairListPage from './PairListPage';
import StorePage from './StorePage';
import { fiatList } from './fiatList.js';
import instantExchanges from './instant-exchanges.json';

const sortedFiatList = [
  'AED',
  'ARS',
  'AUD',
  'AZN',
  'BDT',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CZK',
  'DKK',
  'DOP',
  'EGP',
  'EUR',
  'GBP',
  'GEL',
  'GHS',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IRR',
  'JOD',
  'JPY',
  'KES',
  'KRW',
  'KWD',
  'KZT',
  'LKR',
  'MDL',
  'MMK',
  'MXN',
  'MYR',
  'NAD',
  'NGN',
  'NOK',
  'NZD',
  'OMR',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'ZAR',
];

const App = () => {
  const [loading, setLoading] = useState(true);
  const [tradingPairs, setTradingPairs] = useState();
  const [exchangedata, setExchangeData] = useState([
    {
      attributes: {
        name: 'Exchange Name',
        ecash_brand: true,
        decimal_places: 0,
        score: 0,
        ecash_deposit_address_format: true,
        ecash_website_link: true,
        url: '/',
        deposits_working: true,
        withdrawals_working: true,
        withdrawal_fee: 10,
        fiat_deposit_options: ['USD'],
        trading_pairs: ['USDT'],
      },
    },
  ]);

  const getUnique = (array) => {
    var uniqueArray = [];
    var fiatArray = [];
    const testArray = [
      {
        label: 'Fiat',
        options: [],
      },
      {
        label: 'Crypto',
        options: [],
      },
    ];
    for (let i = 0; i < array.length; i++) {
      if (
        uniqueArray.indexOf(array[i]) === -1 &&
        !sortedFiatList.includes(array[i])
      ) {
        uniqueArray.push(array[i]);
      }
      uniqueArray.sort((a, b) => a.localeCompare(b));
    }
    uniqueArray = uniqueArray.map((str) => ({
      value: str.toLowerCase(),
      label: str,
      image: '/logos/' + str.toLowerCase() + '.png',
    }));
    fiatArray = fiatList.map((item) => {
      const container = {};
      container.value = item.currency.code.toLowerCase();
      container.label = item.currency.code;
      container.image = item.flag;
      return container;
    });
    testArray[0].options.push(...fiatArray);
    testArray[1].options.push(...uniqueArray);
    return testArray;
  };

  const strapiUrl = 'https://api.scorecard.cash';
  const strapiQuery = '?pagination[pageSize]=100&populate=*';

  useEffect(() => {
    fetch(`${strapiUrl}/api/exchanges${strapiQuery}`, {})
      .then((res) => res.json())
      .then((response) => {
        const exchangeData = response.data;
        const allpairs = [];

        for (let i = 0; i < exchangeData.length; ++i) {
          const element = exchangeData[i].attributes;
          const pairs = element.trading_pairs;
          allpairs.push(...pairs);
        }

        const exchangeData2 = response.data.concat(instantExchanges);
        const allTradingPairs = getUnique(allpairs);
        setExchangeData(exchangeData2);
        setTradingPairs(allTradingPairs);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <Router>
        {/* <Header /> */}
        <Route
          exact
          path="/"
          render={(props) => (
            <LandingPage
              {...props}
              exchangedata={exchangedata}
              tradingPairs={tradingPairs}
            />
          )}
        />
        <Switch>
          <Route exact path="/store-ecash" render={() => <StorePage />} />
          <Route
            path="/:pair"
            render={(props) => (
              <PairListPage
                {...props}
                data={exchangedata}
                loading={loading}
                tradingPairs={tradingPairs}
              />
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
