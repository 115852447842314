import React from 'react';

const ExchangeTile = ({
  logo,
  name,
  trading_pairs,
  fiat_deposit_options,
  url,
  score,
}) => {
  return (
    <div className="tile-ctn-outer">
      <div className="tile-ctn">
        <div className="tile-border">
          <div className="tile-inner">
            <div className="tile-logo-score-ctn">
              <img
                src={'https://api.scorecard.cash' + logo.data.attributes.url}
                alt="ecash exchange"
                className="tile-img"
              />
              <div className="tile-scorebox">
                <a
                  className="scorecard-link"
                  href={
                    name === 'StealthEX' ||
                    name === 'Godex' ||
                    name === 'SimpleSwap'
                      ? 'https://scorecard.cash/instant-exchange/' +
                        name
                          .toLowerCase()
                          .replace(/\s/g, '-')
                          .replace(/\./g, '-')
                      : 'https://scorecard.cash/exchange/' +
                        name
                          .toLowerCase()
                          .replace(/\s/g, '-')
                          .replace(/\./g, '-')
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="tile-score"
                    style={
                      score >= 90
                        ? { color: '#00b8d8' }
                        : score >= 75
                        ? { color: '#0074c2' }
                        : { color: '#e69500' }
                    }
                  >
                    {score}
                  </div>
                  <div
                    className="tile-score-label"
                    style={
                      score >= 90
                        ? { color: '#00b8d8' }
                        : score >= 75
                        ? { color: '#0074c2' }
                        : { color: '#e69500' }
                    }
                  >
                    Score
                  </div>
                </a>
              </div>
            </div>
            <div className="tile-divider"></div>
            <div className="tile-label">XEC Trading Pairs</div>
            <div className="tile-pairs-list">{trading_pairs.join(', ')}</div>
            {fiat_deposit_options.length > 0 && (
              <>
                <div className="tile-label">Fiat Deposit Options</div>
                <div className="tile-pairs-list">
                  {fiat_deposit_options.join(', ')}
                </div>
              </>
            )}
            <a className="tile-btn" href={url} target="_blank" rel="noreferrer">
              Buy XEC on {name} &#62;&#62;
            </a>
            {/* <a
              className="scorecard-link"
              href={'https://scorecard.cash/exchange/' + name.toLowerCase()}
              target="_blank"
              rel="noreferrer"
            >
              View Scorecard
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeTile;
