import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import LandingBanner from './Components/LandingBanner';
import TickerScroll from './Components/TickerScroll';
import Circle2 from './images/ecash-circle-1.png';
import { customStyles } from './Components/SelectStyle';
import Video from './images/video-background.mp4';
import BackgroundImg from './images/video-img.jpg';
import 'animate.css';

const LandingPage = ({ loading, tradingPairs }) => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const isMounted = useRef(false);

  const routeChange = () => {
    let path = `${selectedOption.value}`;
    history.push(path);
  };

  const goToAll = () => {
    let path = '/exchanges';
    history.push(path);
  };

  useEffect(() => {
    if (isMounted.current) {
      routeChange();
    } else {
      isMounted.current = true;
    }
  }, [selectedOption]);

  return (
    <>
      <LandingBanner />
      {loading && (
        <div className="loading-ctn">
          <div className="loading-ctn-inner">Loading...</div>
        </div>
      )}
      {!loading && (
        <div>
          <div
            className="landingbackground-image"
            style={{ backgroundImage: 'url(' + BackgroundImg + ')' }}
          />
          <div className="overlay" />
          <div className="video-ctn">
            <video
              playsInline
              autoPlay
              muted
              loop
              poster={BackgroundImg}
              id="ecash_background"
            >
              <source src={Video} type="video/mp4" />
            </video>
          </div>
          <div className="main-ctn">
            <div className="square-ctn animate__animated animate__fadeInUp">
              <h1>How to Buy eCash</h1>
              <a href="https://e.cash/" target="_blank" rel="noreferrer">
                <img src={Circle2} alt="eCash" className="big-e" />
              </a>
              <div
                className="select-line-ctn animate__animated animate__fadeInUp"
                style={{ animationDelay: '.4s' }}
              >
                <h2>
                  I want to buy <span>XEC</span> with
                </h2>
                <div className="select-ctn">
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={tradingPairs}
                    isSearchable
                    styles={customStyles}
                    classNamePrefix="react-select"
                    formatOptionLabel={(currency) => (
                      <div className="currency-option">
                        {currency.image.includes('/logos/') ? (
                          <img src={currency.image} alt={currency.label} />
                        ) : (
                          <img
                            src={'data:image/png;base64,' + currency.image}
                            alt={currency.label}
                          />
                        )}
                        <span>{currency.label}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="explainer-p">
                eCash is available to purchase on many exchanges. The selector
                above has all available XEC trading pairs. Select the currency
                you want for a list of available exchanges.
              </div>
              <div className="see-all-link" onClick={() => goToAll()}>
                See All Exchanges &gt;
              </div>
            </div>
            <TickerScroll />
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPage;
