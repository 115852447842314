import React from 'react';
import Header from './Components/Header';
import SiteLinks from './SiteLinks/SiteLinks';
import Cashtab from './images/cashtab.png';
import Electrum from './images/electrum-abc.png';
import Raipay from './images/raipay.png';
import { ReactComponent as Phone } from './images/phone.svg';
import { ReactComponent as Desktop } from './images/desktop.svg';

const StorePage = () => {
  return (
    <>
      <Header />
      <div className="pair-page-selector-ctn">
        <h2>
          How to store <span>eCash</span>
        </h2>
      </div>
      <div className="pair-page-wrapper">
        <div className="pair-page-subheadline" style={{ textAlign: 'center' }}>
          eCash storage is available on desktop and mobile wallets.
          <br />
          Remember to always backup your wallets.
        </div>
        <h3 className="wallet-header">
          <Desktop />
          Desktop Wallets
        </h3>
        <div className="wallet-list">
          <a
            href="https://www.bitcoinabc.org/electrum/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Electrum} alt="electrum abc" />
          </a>
          <div className="wallet-divider" />
          <a href="https://cashtab.com/" target="_blank" rel="noreferrer">
            <img src={Cashtab} alt="cashtab" />
          </a>
        </div>
        <h3 className="wallet-header" style={{ backgroundColor: '#ff21d0' }}>
          <Phone />
          Mobile Wallets
        </h3>
        <div className="wallet-list" style={{ borderColor: '#ff21d0' }}>
          <a href="https://cashtab.com/" target="_blank" rel="noreferrer">
            <img src={Cashtab} alt="cashtab" />
          </a>
          <div className="wallet-divider" />
          <a href="https://www.raipay.co/" target="_blank" rel="noreferrer">
            <img src={Raipay} alt="raipay" />
          </a>
        </div>
      </div>
      <SiteLinks />
    </>
  );
};

export default StorePage;
