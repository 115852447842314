import React from 'react';
import Fast from '../images/fast-icon.png';
import Reliable from '../images/reliable.png';
import Scalable from '../images/scale.png';
import Rocket from '../images/rocket.png';

const data = [
  { icon: Fast, text: 'eCash is Fast' },
  { icon: Reliable, text: 'eCash is Reliable' },
  { icon: Scalable, text: 'eCash is Scalable' },
  { icon: Rocket, text: 'eCash is the Future!' },
];

const LandingBanner = () => {
  return (
    <div
      className="vwrap animate__animated animate__fadeInDown"
      style={{ animationDelay: '.2s' }}
    >
      <div className="vmove">
        {data.map((item) => (
          <div className="vitem">
            <img src={item.icon} alt="eCash" />
            <h5>{item.text}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingBanner;
