import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/ecash-logo-horizontal.png';

const Header = () => {
  return (
    <div className="header-ctn">
      <Link to="/">
        <img src={Logo} alt="ecash logo" style={{ animationDelay: '.4s' }} />
      </Link>
      <Link className="glow-on-hover" to="/store-ecash">
        How to Store eCash
      </Link>
    </div>
  );
};

export default Header;
