export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#0074c2' : '#000c25',
    cursor: 'pointer',
    '&:hover': {
      background: '#0074c2',
    },
  }),
  control: (provided) => ({
    ...provided,
    background: '#0074c2',
    border: 'none',
    color: '#fff',
    fontWeight: '700',
    fontSize: '30px',
    cursor: 'pointer',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      background: '#FF21D0',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  menu: (provided) => ({
    ...provided,
    background: '#000c25',
    border: '1px solid #0074c2',
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    background: '#fff',
  }),
};
